import {post, get, ResponseType, URL} from "@/utils/axios";
import {WELFARE_BOOK_RATING_IMPORT, FRAGMENT_GET, FRAGMENT_LIST, FRAGMENT_UPDATE} from "@/utils/api/url";


export const getList = (params: {
    id: number | string,
    page: number,
    size: number,
    description: string,
}) => {
    return get(URL.FRAGMENT_LIST, {params})
}
export const getFragment = (params: { id: number | string }) => {
    return get(URL.FRAGMENT_GET, {params})
}
export const update = (params: { id: number, description: string, content: string }) => {
    return post(URL.FRAGMENT_UPDATE, {data: params})
}
